import React from "react";
import Link from "next/link";
import InfiniteScroll from "react-infinite-scroll-component";
import NewNewsCard from "./new-news-card";
import { NewsSummaryFragment } from "@graphql/generated/graphql";
import { Trans } from "@lingui/macro";

interface INewsListProps {
  news: NewsSummaryFragment[];
  showPublisher?: boolean;
}

export const NewsList: React.FunctionComponent<INewsListProps> = ({
  news,
  showPublisher = true,
}) => {
  return (
    <div className="flex flex-col items-stretch divide-y divide-light-background">
      {news.map((news) => (
        <div className="py-4" key={news.id}>
          <Link href={`/news/${news.id}`} passHref>
            <a>
              <NewNewsCard news={news} showPublisher={showPublisher} big />
            </a>
          </Link>
        </div>
      ))}
    </div>
  );
};

interface InfiniteListProps {
  infiniteLoadingOption?: {
    dataLength: number;
    hasMore?: boolean;
    scrollThreshold?: number;
    height?: number;
    next: () => any;
    loader: JSX.Element;
    endMessage?: JSX.Element;
  };
}

export const InfiniteList: React.FunctionComponent<InfiniteListProps> = ({
  infiniteLoadingOption,
  children,
}) => {
  return (
    <InfiniteScroll
      dataLength={infiniteLoadingOption.dataLength}
      hasMore={infiniteLoadingOption.hasMore ?? true}
      loader={infiniteLoadingOption.loader}
      scrollThreshold={infiniteLoadingOption.scrollThreshold}
      height={infiniteLoadingOption.height}
      next={infiniteLoadingOption.next}
      endMessage={
        infiniteLoadingOption.endMessage ?? (
          <p className="py-4 text-center font-sen text-text-lightest">
            <Trans>End of list</Trans>
          </p>
        )
      }
    >
      {children}
    </InfiniteScroll>
  );
};
